import React from "react";
import { Helmet } from "react-helmet";

import Header from "src/components/headers/header";
import Footer from "src/components/footers/footer";
import Form from "src/components/formulaires/form-devis";

export default function index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Demandez jusqu’à 3 devis pour votre étude de sol</title>
        <meta
          name="description"
          content="Devis pour une étude de sol | Vente terrain constructible loi Elan, étude préalable à construction (maison, extension, surélévation…), diagnostic géotechnique après sinistre, ANC"
        />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-lg">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative py-8 px-4 mx-auto max-w-7xl">
            <h1>
              <span className="block mt-2 text-3xl font-bold tracking-tight leading-8 text-center text-gray-900 sm:text-4xl">
                Votre devis d'étude de sol selon un programme d'essais
                géotechniques personnalisé
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl leading-8 text-gray-500">
                Vous souhaitez recevoir un devis pour une étude de sol.
                Remplissez le formulaire ci-dessous et nous vous répondrons dans
                les plus brefs délais.
                <br />
                Besoin d'un bureau d’étude géotechnique ou bureau d’étude de sol
                ? Vente de terrain constructible loi Elan, étude préalable à
                construction (maison, extension, surélévation…), diagnostic
                géotechnique après sinistre, étude de sol pour un assainissement
                non collectif...
                <br />
                Géotechnique Maison.fr est le site expert pour obtenir un devis
                d'étude de sol adapté à votre besoin partout en France. Nous
                fédérons au sein d'un même collectif de praticiens plusieurs
                bureaux d'étude. Envoyez-nous votre demande et nous vous
                répondrons dans les plus brefs délais. Recevez grâce à nous un
                devis de la part de géotechniciens.
              </p>
            </div>
          </div>
        </div>

        <Form />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
